const itemData = [
    {
        img: '/assets/img/publicInstagram/Rectangle25.png',

    },
    {
        img: '/assets/img/publicInstagram/Rectangle26.png',
    },
    {
        img: '/assets/img/publicInstagram/Rectangle27.png',
    },
    {
        img: '/assets/img/publicInstagram/Rectangle28.png',
    },
    {
        img: '/assets/img/publicInstagram/Rectangle29.png',
    },
    {
        img: '/assets/img/publicInstagram/Rectangle30.png',
    },
    {
        img: '/assets/img/publicInstagram/Rectangle31.png',

    },
    {
        img: '/assets/img/publicInstagram/Rectangle32.png',
    },
    {
        img: '/assets/img/publicInstagram/Rectangle33.png',

    }
];

export default itemData;